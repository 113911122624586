 <template>
    <modal
        height="auto"
        :show="true"
        scrollable
        size="middle"
        @hide="$emit('hide')"
    >
        <div slot="header">
            <h2 class="font-normal">Change exception at/details</h2>
        </div>
        <div slot="body">
            <form @submit.prevent="">
                <modal-field label="Exception at" class="mb-4">
                    <multiselect
                        v-model="exceptions_at"
                        :options="prop_exception_at"
                        label="label"
                        track-by="label"
                        class="relative"
                    >   
                    </multiselect>
                </modal-field>

                <modal-field label="Exception details">
                    <multiselect
                        v-model="exceptions_details"
                        :options="prop_exception_details"
                        label="label"
                        track-by="label"
                        class="relative"
                    >   
                    </multiselect>
                </modal-field>
            </form>
        </div>
        <div slot="footer" class="w__100-p">
            <div class="flex justify-end w-full w__100-p">
                <div class="flex flex__justify-end">
                    <button class="px-10 btn-primary" @click="send" :disabled="loading || !valid">Send</button>
                </div>
            </div>
        </div>
    </modal>
</template>
<script>
import axios from 'axios';

export default {
    name: 'XceptionsEditModal',

    props: {
        prop_exception_details: {
            type: Array,
            default: () => { return [] },
        },
        prop_exception_at: {
            type: Array,
            default: () => { return [] },
        },
        xception_id: {
            type: Number,
        }
    },

    data() {
        return {
            loading: false,
            exceptions_at: null,
            exceptions_details: null,
        }
    },

    computed: {
        uuid() {
            return this.$route.params.uuid;
        },

        valid() {
            return this.exceptions_at && this.exceptions_details;
        }
    },

    methods: {
        async send() {
            if (!this.valid) {
                return;
            }
            
            this.loading = true;            

            try {
                const value = {};

                if (this.exceptions_at) {
                    value.exception_at = this.exceptions_at.id;
                }

                if (this.exceptions_details) {
                    value.exception_details = this.exceptions_details.id;
                }

                if (Object.keys(value).length) {
                    await axios.patch(`${this.$apiUrl.exceptions.base}/${this.xception_id}`, value);
                }

                this.$emit('save', {});
                this.$emit('hide');
            } catch(error) {
                this.$snotify.error(error?.response?.data?.message);
            } finally {
                this.loading = false;
            }
        },
    },
}
</script>
